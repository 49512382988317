import { Route, Switch } from "react-router-dom";
import { useHistory } from "react-router-dom";
import React, { lazy, useCallback, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";

import Fab from "@mui/material/Fab";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import Tooltip from "@mui/material/Tooltip";

import { deviceService } from "services/Capacitor";
import { routes } from "./routes";
import { urlService } from "services/Url";

import DashboardShell from "modules/dashboard/Organisms/DashboardShell";
import DashboardTitle from "modules/dashboard/Atoms/DashboardTitle";
import LoadEntitiesProvider from "modules/_common/organisms/LoadEntitiesProvider";

import * as routingConstants from "lib/routing";

export const drawerWidth = 200;
export const mainWidth = drawerWidth;

const useStyles = makeStyles(() => ({
  fabContainer: {
    position: "fixed",
    bottom: 15,
    right: 15,
    opacity: 0.3,
    transition: ".3s",
    zIndex: 1000,

    "&:hover": {
      opacity: 1,
    },
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    urlService.urlRoot = routingConstants.ROUTE_DASHBOARD_PREFIX;
  }, []);

  const navigateToApp = useCallback(() => {
    history.push(routingConstants.ROUTE_APP_PREFIX);
  }, []);

  return (
    <LoadEntitiesProvider>
      <DashboardShell>
        <>
          <Switch>
            {routes.map((route) => (
              <Route path={route.path} key={route.path}>
                <DashboardTitle route={route} addOffset>
                  {(deviceService.isMobile && route.onMobile) || route.elem}
                </DashboardTitle>
              </Route>
            ))}
          </Switch>
          <div className={classes.fabContainer}>
            <Tooltip title="App View">
              <Fab
                onClick={navigateToApp}
                size="medium"
                color="primary"
                aria-label="app"
              >
                <PhoneIphoneIcon></PhoneIphoneIcon>
              </Fab>
            </Tooltip>
          </div>
        </>
      </DashboardShell>
    </LoadEntitiesProvider>
  );
};

export default Dashboard;
